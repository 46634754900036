/**
 * File: currency.data
 * Created by Ricky Leung on 2018-11-18
 */
export const CURRENCY_LIST = [
  // { value: "AUD", viewValue: "Australian Dollar", fractional: "Cent", minRange: 0, maxRange: 100 },
  // { value: "GBP", viewValue: "British Pound", fractional: "Penny", minRange: 0, maxRange: 100 },
  // { value: "CAD", viewValue: "Canadian Dollar", fractional: "Cent", minRange: 0, maxRange: 100 },
  { value: "RMB", viewValue: "Renminbi/Chinese Yuan", fractional: "Cent", minRange: 0, maxRange: 100 },
  // { value: "EUR", viewValue: "Euro", fractional: "Cent", minRange: 0, maxRange: 100 },
  { value: "HKD", viewValue: "Hong Kong Dollar", fractional: "Cent", minRange: 0, maxRange: 100 },
  // { value: "ISK", viewValue: "Icelandic Krona", fractional: "Eyrir", minRange: 0, maxRange: 100 },
  // { value: "JPY", viewValue: "Japanese Yen", fractional: "Sen", minRange: 0, maxRange: 100 },
  // { value: "MYR", viewValue: "Malaysian Ringgit", fractional: "Sen", minRange: 0, maxRange: 100 },
  // { value: "MXN", viewValue: "Mexican Peso", fractional: "Centavo", minRange: 0, maxRange: 100 },
  // { value: "TWD", viewValue: "New Taiwan Dollar", fractional: "Cent", minRange: 0, maxRange: 100 },
  // { value: "NZD", viewValue: "New Zealand Dollar", fractional: "Cent", minRange: 0, maxRange: 100 },
  // { value: "SGD", viewValue: "Singapore Dollar", fractional: "Cent", minRange: 0, maxRange: 100 },
  // { value: "KRW", viewValue: "South Korean Won", fractional: "Jeon", minRange: 0, maxRange: 100 },
  // { value: "USD", viewValue: "United States Dollar", fractional: "Cent", minRange: 0, maxRange: 100 },
  // { value: "VND", viewValue: "Vietnamese Dong", fractional: "Hao", minRange: 0, maxRange: 10 }
];
