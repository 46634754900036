/**
 * File: userroleslist
 * Created by Ricky Leung on 2018-03-04
 */
export const USER_ROLES_LIST = [
  {
    key: "BRAND_ADMIN",
    phraseKey: "USER_ROLE_BRAND_ADMIN",
    phrase: "Administrator"
  },
  {
    key: "BRAND_PROCUREMENT_MANAGER",
    phraseKey: "USER_ROLE_BRAND_PROCUREMENT_MANAGER",
    phrase: "Procurement Manager"
  },
  {
    key: "BRAND_MARKET_MANAGER",
    phraseKey: "USER_ROLE_BRAND_MARKET_MANAGER",
    phrase: "Market Manager"
  },
  {
    key: "BRAND_BUSINESS_ANALYST",
    phraseKey: "USER_ROLE_BRAND_BUSINESS_ANALYST",
    phrase: "Business Analyst"
  },
  {
    key: "BRAND_ACCOUNTABLE",
    phraseKey: "USER_ROLE_BRAND_ACCOUNTS_RECEIVABLE",
    phrase: "Accounts Receivable"
  },
  {
    key: "BRAND_SYSTEM_ANALYST",
    phraseKey: "USER_ROLE_BRAND_SYSTEM_ANALYST",
    phrase: "System Analyst"
  },
  {
    key: "Consumer",
    phraseKey: "USER_ROLE_CONSUMER",
    phrase: "Consumer"
  }
];

export const USER_ACTIVE_STATE = {
  active: "USER_STATE_ACTIVE",
  inactive: "USER_STATE_INACTIVE"
};
